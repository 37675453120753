.sub-menu {
  &__item {
    position: relative;
    display: block;
    overflow: hidden;

    @include breakpoint(medium down) {
        margin-bottom: rem-calc(20);
    }  

    &:hover {
      .sub-menu__bg {
        transform: scale(1.1)
      }
      .sub-menu__title {
        background: rgba(0,0,0,0.9);
      }
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;

        width: 30%;
        height: 100%;
        background: $ha-green;

        z-index: 1;

        left: 88%;
        transform: skewX(15deg);

        // @include breakpoint(medium down) {
        //     z-index: -10;
        // }        
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;

        width: 30%;
        height: 100%;
        background: $ha-green;

        z-index: 1;

        left: -17.5%;
        transform: skewX(15deg);

        // @include breakpoint(medium down) {
        //     z-index: -10;
        // }
    }

  }

  &__bg {
    background: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 31vw;
    transition: all 0.35s ease-in-out;

    // @include breakpoint(medium down) {
    //     background-position-y:  top;
    // }

  }

  &__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,0.5);
    padding: 10px;
    margin: 0 0 0 rem-calc(35);
    color: white;
    transition: all 0.35s ease-in-out;

    @media only screen and (max-width: 1200px) {
      font-size: 28px;
    }

    @media only screen and (max-width: 1125px) {
      font-size: 26px;
      margin-left: rem-calc(30);
    }

    @media only screen and (max-width: 1020px) {
      font-size: 24px;
      margin-left: rem-calc(25);
    }

    @media only screen and (max-width: 900px) {
      font-size: 22px;
      margin-left: rem-calc(19);
    }

    @media only screen and (max-width: 820px) {
      font-size: 20px;
      margin-left: rem-calc(15);
    }

    @media only screen and (max-width: 735px) {
      font-size: 18px;
      margin-left: rem-calc(12);
    }

    @media only screen and (max-width: 620px) {
      font-size: 16px;
      margin-left: rem-calc(8);
    }

    // @include breakpoint(medium down) {
    //     margin: 0;
    // }
  }
}
