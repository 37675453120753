.teams {
  &--member {
    position: relative;
    // display: flex;
    // justify-content: center;

    &__logo {
      background-color: $ha-green;
      max-width: 267px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  &--image {
    border: $ha-green 2px solid;
  }
  &--name, &--misc {
    position: absolute;
    bottom: 0;
    color: $white;
    padding: rem-calc(10);
    margin-bottom: 0;
    font-weight: 600;

    @include breakpoint(medium only) {
      font-size: rem-calc(15);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(13);
    }

    &__team {
      width: 100%;
      bottom: auto;
      font-size: rem-calc(36);

      @include breakpoint(small only) {
        font-size: rem-calc(21.6);
      }
    }
  }
  &--misc {
    top: 0;
  }
}