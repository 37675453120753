@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

h1 {
  font-weight: 700;
  letter-spacing: 8px;
  text-align: center;
  span {
    font-weight: 400;
    display: inherit;
    font-size: rem-calc(26);
  }
}

p {
  max-width: rem-calc(650);
  text-align: center;

  a {
    color: $ha-green;
  }
}