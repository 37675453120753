
.masthead-wrapper {
	margin-top: rem-calc(20);
	background-color: $white;
	border-bottom: 2px solid $ha-green;
	z-index: 10;

	@include breakpoint(medium down) {
		padding-bottom: rem-calc(10);
		position: sticky;
		top: rem-calc(-40);
		margin-top: 0;


	}
}

.masthead {
  display: flex;
  justify-content: center;
  align-items: center;

  &--logo {
		z-index: 10;
		display: flex;
    flex-direction: column;
		align-items: center;
    transition: all 0.35s ease-in-out;
        
        &__mobile {
            z-index: 2;
            display: flex;
        }

		@include breakpoint(medium down) {
			flex-direction: row;
			transition: all 0.35s ease-in-out;
		}
	}
	
	&--title {
		text-transform: uppercase;
		font-size: rem-calc(22);
		display: inherit;

		@include breakpoint(medium down) {
			margin-top: rem-calc(5);
			margin-bottom: 0;
		}

		@media only screen and (max-width: 320px) {
			font-size: rem-calc(17);
		}

		span {
			font-size: rem-calc(18);

			@media only screen and (max-width: 320px) {
				font-size: rem-calc(14);
			}
		}
	}
}

.ha-logo {
  fill: $black;
	max-width: rem-calc(300);
	width: 100%;
	transition: all 0.25s ease-in-out;

	&--small {
		max-width: rem-calc(150);
		margin-right: rem-calc(20);

		@include breakpoint(small only) {
			max-width: rem-calc(85);
			margin-right: rem-calc(10);
		}
	}

	&--sticky {
		right: rem-calc(10);
		width: rem-calc(50);
		height: rem-calc(21.5);
		// top: rem-calc(74);

		// display: inline-block !important;
    position: absolute;
		// opacity: 0;
		// width: 0;
		// height: 0;
	
		// @include breakpoint(medium down) {
		// 	display: none;
		// }
	}

	&--mobile {
        width: rem-calc(125);
        // margin-top: rem-calc(35);
	}

}

// STYLE MENUS ( Primary )============================================
.menu-primary-wrap
{
	padding: rem-calc(5);
	// background: $bg-primary-nav;
	font-size: rem-calc(18);
	// height: $ht-primary-nav;
	width: 100%;
	position: absolute;
	top: rem-calc(62);
	left: 0;
	background-color: $white;
	transition: all 0.35s ease-in-out;

	@include breakpoint(medium down) {
		top: rem-calc(10);
		// margin-top: rem-calc(35);
	}
}


.menu-primary {
    width: 49%;
		display: inline-block;
		
		&__right {
			// padding-left: rem-calc(10);
			.menu {
				margin-left: rem-calc(102);
			}
		}

		&__left {
			// padding-right: rem-calc(10);
			.menu {
				margin-right: rem-calc(15);
			}
		} 

	.menu > li > a
	{
		text-decoration: none;
		text-align: center;
		border-radius: 0;
		margin-left: rem-calc(5);
		text-transform: uppercase;
    // background: $bg-primary-nav-off;
		// color: $fg-primary-nav-off;
    font-size: rem-calc(18);
    font-weight: 600;
		transition: background-color 0.25s ease-out, color 0.25s ease-out;
	}



	// .menu .active > a,
	// .menu .active > a:hover
	// {
	// 	// background: $bg-primary-nav-active;
	// 	// color: $fg-primary-nav-active;
	// }
}

// .menu-centered > .menu li,
// .menu-icon
// {
//     z-index: 1002;
// }

.off-canvas {
	.close-button{
		z-index: 10;
		top: 0;
		color: $ha-green;
	}

	.vertical {
		font-weight: 600;
	}
}

.fix-menu {
	position: sticky;
	top: -85px;
	z-index: 10;
	border-bottom: 2px solid $ha-green;
	// transition: all 0.15s ease-in-out;

	@include breakpoint(medium down) {
    top: rem-calc(0);
	}

	@include breakpoint(large) {
		.menu-primary-wrap {
			top: rem-calc(95);
		}
	}

	.ha-logo--large {
		opacity: 0;
		// width: 175.5px;
		transition: all 0.15s ease-in-out;
	}

	.ha-logo--sticky {
		display: inline-block !important;
		position: absolute;
		top: rem-calc(105);
		width: rem-calc(50);
		height: rem-calc(21.5);
		opacity: 1;
		// transition: all 2s ease-in-out;
		animation-name: sticky-logo;
		animation-delay: 2s;
		animation-duration: 4s;
		animation-iteration-count: infinite;

		@include breakpoint(medium down) {
			top: rem-calc(25);
		}
  }
    
    // .masthead--logo__mobile {
    //     @include breakpoint(medium down) {
    //         margin-top: rem-calc(40);
    //     }
    // }
}

@keyframes sticky-logo {
	0% { transform: rotateY(0deg); }
	50% { fill: $ha-green; }
	100% { transform: rotateY(360deg); }
}

.title-bar {
	top: 2px;
	position: absolute;
	z-index: 11;

	.menu-icon {
	text-indent: -9999px;
}
}


