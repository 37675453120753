h1, h2 {
  text-transform: uppercase;
  color: $ha-green;
  display: inline-flex;
  align-items: center;

  .span-middle {
    font-size: rem-calc(28);
    line-height: rem-calc(20);
    margin-left: rem-calc(10);
  }
}

h2 {
  font-weight: bold;
}

.space-bottom {
  margin-bottom: rem-calc(20) !important;
}

.news-alert {
  margin-top: rem-calc(10);
  a {
    display: inherit;
  }

  h1 {
    display: inherit;
    font-size: rem-calc(20);
    font-weight: bold;
    text-transform: uppercase;
    color: $ha-green;
  }
}

// old

.logo-wrapper, .league-name, .copy-wrapper, .event, .social-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem-calc(40);


}

.league-name {
  margin-top: rem-calc(20);
  text-transform: uppercase;
}

.copy-wrapper {
  margin-bottom: rem-calc(10);
  flex-direction: column;
}

.para {
  &--emph {
    font-size: rem-calc(18);
    font-weight: normal;
  }
  
}









