
.footer {
  padding: rem-calc(40);
  margin-top: rem-calc(40);
  background-color: $ha-green;
}

.social-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: rem-calc(40);
}

.aff-wrapper {
  padding-bottom: rem-calc(40);
  img, svg {
    max-height: rem-calc(75);
    width: auto;
  }
  a {
    padding: rem-calc(20);
  }
}

.footer-nav a {
  color: $white;
}

.footer .fab {
  color: #5dae83;
  width: rem-calc(48);
  height: rem-calc(48);
  overflow: hidden;

  &:before {
    color: #fff;
    // font-size: 3em !important;
  }
}