.banner__caption {
  // left: 50%;
  // top: 50%;
  // transform: translateX(-50%) translateY(-50%);
  // width: auto;
  // bottom: auto;
  
  a {
    display: inherit;
  }

  h1 {
    display: inherit;
    font-size: rem-calc(20);
    font-weight: bold;
    text-transform: uppercase;
    color: $ha-green;
  }
}

.orbit-caption {
  background-color: rgba(10, 10, 10, 0.9);
}
