.event {
  margin: rem-calc(10);
  padding: rem-calc(10);
  border-bottom: 1px solid $black;

  @include breakpoint(small only) {
    margin-top: 0;
    padding-top: 0;
  }

  &:first-child {
    border-top: none;
  }

  &--title {
    font-size: rem-calc(24);
    letter-spacing: 0;
    color: $black;
  }

  &--image {
    display: flex;
    justify-content: center;

    // @include breakpoint(small only) {
    //   justify-content: flex-start;
    //   &__away {
    //     justify-content: flex-end;
    //   }
    // }
  }

  &--info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--result {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  &--score {
    font-size: rem-calc(38);
    width: rem-calc(75);
  }

  &--date-wrapper {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &--date {
    display: inline-block;
    margin-bottom: rem-calc(10);

    &__to {
      display: inline;
    }
  }

  &--teams {
    display: inline-block;
    font-size: rem-calc(30);
    text-align: center;
    font-weight: 600;

    span {
      display: inherit;
    }
  }  
}